<template>
  <div>
    {{ getMessage }}
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  props: {
    show: Boolean,
    type: String,
    message: String,
  },
  data() {
    return {
      duration: 7000
    }
  },
  computed: {
    ...mapGetters({
      getShowNotification: 'app/getShowNotification',
      getNotificationType: 'app/getNotificationType',
      getNotificationMsg: 'app/getNotificationMsg',
    }),

    getMessage: function () {
      if (this.getShowNotification) {
        this.$toasted.show(this.getNotificationMsg, {
          action: {
            text: 'Cerrar',
            onClick: (e, toastObject) => {
              toastObject.goAway(0)
              this.$store.dispatch('app/hideNotification')
            }
          },
          type: this.getNotificationType,
          theme: 'bubble',
          duration: this.duration,
          position: 'top-center'
        })
        setTimeout(() => {
          this.$store.dispatch('app/hideNotification')
        }, this.duration)
      }
    },
  }
}
</script>

<style scoped>
  .text-white {
    color: #ffffff;
  }
</style>