import axios from "axios"

export default {
    loadUser(state, data) {
        state.user = data.data.user
        state.role = data.serverData.role
        state.sector = data.serverData.sector
        state.avatarSrc = 'images/' + data.serverData.avatarSrc
        localStorage.setItem('user', data.data.user)
        localStorage.setItem('role', data.serverData.role)
        localStorage.setItem('sector', data.serverData.sector)
        localStorage.setItem('avatarSrc', 'images/' + data.serverData.avatar)
    },
    loadAuth(state, data) {
        state.status = 'success'
        state.authenticated = true
        state.token = data.serverData.token
        localStorage.setItem('authenticated', 'true')
        localStorage.setItem('user-token', data.serverData.token)
        axios.defaults.headers.common['Authorization'] = `Bearer ${data.serverData.token}`
    },
    loadParameters(state, parameters) {
        state.appParameters = parameters
    },
    loadSectorsByUser(state, sectors) {
        state.sectorsByUser = sectors
    },
    loadUserNotifications(state) {
        const notifications = localStorage.getItem('notifications')

        state.notifications = notifications
            ? JSON.parse(notifications)
            : []
    },
    loadHasNewNotifications(state) {
        state.hasNewNotifications = localStorage.getItem('hasNewNotifications') === 'true'
    },

    logout(state) {
        state.status = ''
        state.authenticated = false
        localStorage.setItem('authenticated', 'false')
        localStorage.removeItem('avatarSrc')
        localStorage.removeItem('user-token')
        delete axios.defaults.headers.common["Authorization"]
    },

    uploadParameters(state, parameters) {
        state.appParameters = parameters
    },

    setViewTitle(state, title) {
        state.viewTitle = title
    },
    setFromSectorIds(state, ids) {
        state.fromSectorIds = ids
        localStorage.setItem('fromSectorIds', ids)
    },
    setToSectorIds(state, ids) {
        state.toSectorIds = ids
        localStorage.setItem('toSectorIds', ids)
    },

    setShowNotification(state, show) {
        state.showNotification = show
    },
    setHasNewNotifications(state, value) {
        state.hasNewNotifications = value
        localStorage.setItem('hasNewNotifications', value)
    },
    setNotificationType(state, type) {
        state.notificationType = type
    },
    setNotificationMsg(state, msg) {
        state.notificationMsg = msg
    },
    addNotification(state, notification) {
        let notifications = state.notifications;
        if (notifications.length > 60) {
            notifications.pop()
        }
        notifications.unshift(notification)
        localStorage.setItem('notifications', JSON.stringify(notifications))
    },
    removeAllNotifications(state) {
        state.notifications = []
        localStorage.setItem('notifications', JSON.stringify([]))
    },
    removeNotification(state, index) {
        state.notifications.splice(index, 1)
        localStorage.setItem('notifications', JSON.stringify(state.notifications))
    },

    setLoadingTable(state, loading) {
        state.loadingTable = loading
    },
    setTableOptions(state, options) {
        state.tableOptions = options
    },
    setPage(state, page) {
        state.page = page
    },
    setItemsPerPage(state, itemsPerPage) {
        state.itemsPerPage = itemsPerPage
    },
    setItemsPerPageOptions(state, itemsPerPageOptions) {
        state.itemsPerPageOptions = itemsPerPageOptions
    },
    setTotalItems(state, totalItems) {
        state.totalItems = totalItems
    },
    setTotalPages(state, totalPages) {
        state.totalPages = totalPages
    },
    setLastPaginatedEndpoint(state, endpoint) {
        state.lastPaginatedEndpoint = endpoint
    }
}