import loginEndpoints from "@/store/endpoints/loginEndpoints";
import appEndpoints from "@/store/endpoints/appEndpoints";
import {uuid} from "vue-uuid";

export default {
    async login(context, data) {
        const response = await loginEndpoints.login(data)
        if (response && response.status === 200) {
            context.commit('loadUser', {data: data, serverData: response.data})
            context.commit('loadAuth', {data: data, serverData: response.data})
        }

        if (response.response && response.response.status === 307) {
            context.commit('loadAuth', {data: data, serverData: response.response.data})
            return response.response
        }

        return response
    },
    async updatePassword(context, data) {
        return await loginEndpoints.updatePassword(data)
    },
    async logout(context) {
        await loginEndpoints.logout()
        context.commit('logout')
    },

    getNotifications(context) {
        context.commit('loadUserNotifications')
        context.commit('loadHasNewNotifications')
    },
    launchNotification(context, notification) {
        context.commit('setShowNotification', true)
        context.commit('setNotificationType', notification.type)
        context.commit('setNotificationMsg', notification.message)
    },
    hideNotification(context) {
        context.commit('setShowNotification', false)
        context.commit('setNotificationType', '')
        context.commit('setNotificationMsg', '')
    },
    addNotificationToList(context, notification) {
        notification.id = uuid.v4()
        context.commit('addNotification', notification)
        context.commit('setHasNewNotifications', true)
    },

    async getParameters(context) {
        const response = await appEndpoints.getParameters()
        if (response.status === 200) {
            context.commit('loadParameters', response.data)
        }
        return response.data
    },
    async saveParameters(context, data) {
        const response = await appEndpoints.saveParameters(data)
        if (response.status === 201) {
            context.commit('uploadParameters', data)
        }
    },

    async getExchangeRate() {
        const response = await appEndpoints.getExchangeRate()
        return response.data
    },

    async getSectorsByUser(context, userId) {
        const response = await loginEndpoints.getSectorsByUser(userId)
        if (response.status === 200) {
            context.commit('loadSectorsByUser', response.data)
        }
        return response
    },

    async setFromSectorIds(context, ids) {
        context.commit('setFromSectorIds', ids)
    },
    async setToSectorIds(context, ids) {
        context.commit('setToSectorIds', ids)
    },
}