<template>
  <div>
    <v-app-bar>
      <v-app-bar-nav-icon @click.stop="showMenu = !showMenu"></v-app-bar-nav-icon>
      <v-toolbar-title class="ml-4">{{ getViewTitle }}</v-toolbar-title>

      <VSpacer/>
      <v-icon
        v-if="isDevelopment"
        class="mr-10"
        @click="$emit('refresh-view')"
      >
        mdi-refresh
      </v-icon>
      <v-menu
        :close-on-content-click="false"
        offset-y nudge-bottom="10"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            icon class="mr-5" :color="getHasNewNotifications ? 'primary' : ''"
            v-bind="attrs" v-on="on"
            @click="onNotificationsOpened"
          >
            {{ getHasNewNotifications ? 'mdi-bell-badge-outline' : 'mdi-bell-outline' }}
          </v-icon>
        </template>

        <v-card width="500px" max-height="500px" style="overflow-y: auto">
          <v-container fluid>
            <div class="mt-1 mb-3">
              <a v-if="getNotifications.length > 0" @click="clearAllNotifications">Borrar todo</a>
              <span v-else>No hay notificaciones para mostrar</span>
            </div>
            <div v-for="notification in getNotifications" :key="notification.id">
              <v-alert
                dense text dismissible close-icon="mdi-close"
                :icon="getNotificationIcon(notification.type)" :type="notification.type"
                @input="removeNotification(notification)"
              >
                {{ notification.message }}
              </v-alert>
            </div>
          </v-container>
        </v-card>
      </v-menu>
      <v-icon class="mr-3" @click="toggleTheme">{{ getThemeIcon }}</v-icon>
    </v-app-bar>

    <v-navigation-drawer
      dark color="primary darken-1" temporary app width="400"
      v-model="showMenu"
    >
      <Navigation :show-menu="showMenu" @on-hide-menu="showMenu = false"/>
    </v-navigation-drawer>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Navigation from "@/components/layout/Navigation.vue";

export default {
  name: "AppBar",
  components: {Navigation},
  emits: ['refresh-view'],
  data() {
    return {
      showMenu: true,
    }
  },
  computed: {
    ...mapGetters({
      getViewTitle: "app/getViewTitle",
      getHasNewNotifications: "app/getHasNewNotifications",
      getNotifications: "app/getNotifications",
    }),
    getThemeIcon() {
      return this.$vuetify.theme.dark
        ? 'mdi-white-balance-sunny'
        : 'mdi-weather-night'
    },
    isDevelopment() {
      return process.env.NODE_ENV === 'development'
    },
    getNotificationIcon() {
      return notificationType => {
        switch (notificationType) {
          case 'success':
            return 'mdi-check-circle'
          case 'warning':
            return 'mdi-alert'
          case 'error':
            return 'mdi-close-circle'
          default:
            return 'mdi-information'
        }
      }
    },
  },
  methods: {
    toggleTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
    },
    onNotificationsOpened() {
      this.$store.commit('app/setHasNewNotifications', false)
    },
    clearAllNotifications() {
      this.$store.commit('app/removeAllNotifications')
    },
    removeNotification(notification) {
      this.$store.commit('app/removeNotification', this.getNotifications.indexOf(notification))
    },
  }
}
</script>

<style scoped>

</style>