<template>
  <v-app>
    <AppBar
      v-if="authenticated"
      @refresh-view="viewKey ++"
    >
    </AppBar>
    <!--    Dialogs -->
    <DialogLoading
      :is-loading="isLoading && !getLoadingTable"
    />
    <AlertNotification/>
    <v-main>
      <router-view
        :key="viewKey"
        @refresh-view="viewKey ++"
      />
    </v-main>
  </v-app>
</template>

<script>
import {mapGetters} from 'vuex';
import Navigation from './components/layout/Navigation.vue'
import axios from "axios";
import DialogLoading from "@/components/shared/DialogLoading.vue";
import SnackBar from "@/components/shared/SnackBar.vue";
import AlertNotification from "@/components/shared/AlertNotification.vue";
import {debug} from "@/store/config";
import {loadBaseResources} from "@/utils/resourcesLoader";
import AppBar from "@/components/layout/AppBar.vue";

export default {
  name: 'App',

  components: {
    AppBar,
    AlertNotification,
    SnackBar,
    DialogLoading,
    Navigation,
  },

  data() {
    return {
      isLoading: false,
      requestMethod: '',
      viewKey: 0
    }
  },
  computed: {
    ...mapGetters({
      authenticated: 'app/getIfAuthenticated',
      getLoadingTable: 'app/getLoadingTable',
    })
  },
  created() {
    this.$store.dispatch('app/getNotifications')
    axios.interceptors.request.use(
      request => this.handleRequest(request),
      error => error
    )
    axios.interceptors.response.use(
      response => this.handleSuccessResponse(response),
      error => this.handleErrorResponse(error)
    )

    loadBaseResources()
  },
  mounted() {
    const dark = localStorage.getItem('dark_theme')
    this.$vuetify.theme.dark = dark === 'true'
  },
  methods: {
    handleRequest(request) {
      if (debug === 'true') {
        request.url += (request.url.indexOf('?') === -1 ? '?' : '&') + 'XDEBUG_SESSION_START=PHPSTORM';
      }
      const isPagination = request.params?.isPagination
      if (isPagination) {
        this.$store.commit('app/setLastPaginatedEndpoint', request.url, {root: true})
      }
      // Token para las request
      const token = localStorage.getItem('user-token')
      if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
      }

      this.isLoading = true
      this.requestMethod = request.method

      return request
    },
    handleSuccessResponse(response) {
      this.isLoading = false
      return response
    },
    handleErrorResponse(error) {
      // console.log(error.response)
      this.isLoading = false
      if (error.response) {
        let statusCode = error.response.status;
        if (statusCode === 307) {
          return error
        }
        let errorMsg = error.response.data.message ?? error.response.data
        if (errorMsg instanceof ArrayBuffer) {
          const decoder = new TextDecoder('utf-8');
          const messageObject = JSON.parse(decoder.decode(errorMsg))
          errorMsg = messageObject.message
        }
        const notification = {
          type: statusCode === 500 ? 'error' : 'warning',
          message: errorMsg
        }
        if (statusCode !== 401) {
          this.$store.dispatch('app/launchNotification', notification)
          this.$store.dispatch('app/addNotificationToList', notification)
        }

        if (statusCode === 401) {
          this.$store.commit('app/logout')
          this.$router.push('/login').catch(() => {
          })
        }
      }
      throw error
    },
  }
};
</script>

<style>
@import "../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../node_modules/ag-grid-community/dist/styles/ag-theme-balham.css";
@import "../node_modules/ag-grid-community/dist/styles/ag-theme-blue.css";

</style>
