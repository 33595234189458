import axios from "axios";
import {host} from "@/store/config";

export default {

    async getIVACategories() {
        return axios.get( host + '/tax/iva-categories')
    },
    async getPeriodClose() {
        return axios.get( host + '/accounting_period_close')
    },
    async getPaymentMethods() {
        return await axios.get( host + '/payment_method')
    },
    async getCollectionMethods() {
        return await axios.get( host + '/customer_payment_methods')
    },
    async getSupplierPaymentMethods() {
        return await axios.get( host + '/supplier_payment_methods')
    },
    async getAccountPlannings(params) {
        return await axios.get( host + '/account_planning', {
            params: params
        })
    },
    async getCompanyCurrentAccounts(params) {
        return await axios.get( host + '/account/current-account-find-all', {
            params: params
        })
    },
    async getBranches() {
        return await axios.get( host + '/account_branch')
    },
    async getCostCenters() {
        return await axios.get( host + '/accounting/cost-center')
    },
    async getLedgerAccountMovements(params) {
        return await axios.get( host + '/ledger_account_searcher', {
            params: params
        })
    },
    async getPaymentMethodAccountParameters(params) {
        return await axios.get( host + '/payment_method_account_parameter', {
            params: params
        })
    },
    async getCommonAccountingEntries(params) {
        return await axios.get( host + '/common_accounting_entry', {
            params: params
        })
    },
    async getAccountEntryMovements(accountEntryId) {
        return await axios.get( host + '/account_entry_movement_searcher', {
            params: {
                accountEntryId
            }
        })
    },
    async getRetentionPerceptionParameters(criteria) {
        return await axios.get( host + '/tax/retention_perception_parameter', {
            params: {
                data: JSON.stringify(criteria)
            }
        })
    },
    async getTaxTypes() {
        return await axios.get( host + '/tax_types')
    },

    async createOrEditAccountPlanning(account) {
        return await axios.post( host + '/account_planning', account)
    },
    async deleteAccountPlanning(id) {
        return await axios.delete( host + '/account_planning', {
            params: {
                id: id
            }
        })
    },
    async createOrEditCostCenter(costCenter) {
        return await axios.put( host + '/accounting/cost-center', costCenter)
    },
    async deleteCostCenter(id) {
        return await axios.delete( host + '/accounting/cost-center', {
            params: {
                id: id
            }
        })
    },
    async createOrEditAccountingEntry(account) {
        return await axios.post( host + '/common_accounting_entry', account)
    },
    async deleteAccountingEntry(id) {
        return await axios.delete( host + '/common_accounting_entry', {
            params: {
                id
            }
        })
    },
    async createOrEditPaymentMethodAccountParameter(paymentMethodAccount) {
        return await axios.post( host + '/payment_method_account_parameter', paymentMethodAccount)
    },
    async separatePaymentMethodAccount(accountId) {
        return await axios.put(host + '/payment_method_account_separate', null, {
            params: {
                accountId
            }
        })
    },
    async deletePaymentMethodAccountParameter(id) {
        return await axios.delete( host + '/payment_method_account_parameter', {
            params: {
                id
            }
        })
    },
    async createOrEditPeriodClose(data) {
        return axios.put( host + '/accounting_period_close', data)
    },
    async deletePeriodClose(id) {
        return axios.delete( host + '/accounting_period_close', {
            params: {
                id: id
            }
        })
    },
    async createOrEditRetentionPerceptionParameter(retentionPerceptionParameter) {
        return await axios.put( host + '/tax/retention_perception_parameter', retentionPerceptionParameter)
    },
    async deleteRetentionPerceptionParameter(id) {
        return await axios.delete( host + '/tax/retention_perception_parameter', {
            params: {
                id
            }
        })
    }
}