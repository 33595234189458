export const Entities = {
  Suppliers: 'suppliers',
  Customers: 'customers',
  Products: 'products',
  PurchaseOrders: 'purchaseOrders',
  QualityProductReceptions: 'qualityProductReceptions',
  CustomerOrders: 'customerOrders',
  CommercialSupplierProofs: 'commercialSupplierProofs',
}

export const {
  Suppliers,
  Customers,
  Products,
  PurchaseOrders,
  QualityProductReceptions,
  CustomerOrders,
  CommercialSupplierProofs,
} = Entities