export default {
    getIVACategories(state) {
        return state.IVACategories
    },
    getPeriodClose(state) {
        return state.periodClose
    },
    getPaymentMethods(state) {
        return state.paymentMethods
    },
    getCollectionMethods(state) {
        return state.collectionMethods
    },
    getSupplierPaymentMethods(state) {
        return state.supplierPaymentMethods
    },
    getAccountPlannings(state) {
        return state.accountPlannings
    },
    getBranches(state) {
        return state.branches
    },
    getCostCenters(state) {
        return state.costCenters
    },
    getLedgerAccountMovements(state) {
        return state.ledgerAccountMovements
    },
    getLedgerDebitTotal(state) {
        return state.ledgerAccountBalances.debitTotal
    },
    getLedgerCreditTotal(state) {
        return state.ledgerAccountBalances.creditTotal
    },
    getCommonAccountingEntries(state) {
        return state.commonAccountingEntries
    },
    getCompanyCurrentAccounts(state) {
        return state.companyCurrentAccounts
    },
    getPaymentMethodAccountParameters(state) {
        return state.paymentMethodAccountParameters
    },
    getTaxTypes(state) {
        return state.taxTypes
    },
    getRetentionPerceptionParameters(state) {
        return state.retentionPerceptionParameters
    }
}