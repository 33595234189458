export default {
    drawer: true,
    viewTitle: '',
    status: '', // Status de las request
    isLoading: false,
    token: localStorage.getItem('user-token'),
    authenticated: ('true' === localStorage.getItem("authenticated")),
    user: localStorage.getItem("user"),
    sector: localStorage.getItem("sector"),
    avatarSrc: localStorage.getItem("avatarSrc"),
    role: localStorage.getItem("role"),
    fromSectorIds: localStorage.getItem("fromSectorIds"),
    toSectorIds: localStorage.getItem("toSectorIds"),
    favsLinksOnState: [],
    appParameters: [],
    smallScreenHeight: 800,
    sectorsByUser: [],

    showNotification: false,
    hasNewNotifications: false,
    notificationType: '',
    notificationMsg: '',
    notifications: [],

    loadingTable: false,
    tableOptions: {
        page: 1,
        itemsPerPage: 10
    },
    itemsPerPage: 10,
    page: 1,
    totalItems: 0,
    totalPages: 0,
    itemsPerPageOptions: [5, 10, 20, 30, 50],
    lastPaginatedEndpoint: ''
}