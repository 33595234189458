export default {
    loadIVACategories(state, categories) {
        state.IVACategories = categories
    },
    loadPeriodClose(state, periodClose) {
        state.periodClose = periodClose
    },
    async loadPaymentMethods(state, paymentMethods) {
        state.paymentMethods = paymentMethods
    },
    async loadCollectionMethods(state, collectionMethods) {
        state.collectionMethods = collectionMethods
    },
    async loadSupplierPaymentMethods(state, supplierPaymentMethods) {
        state.supplierPaymentMethods = supplierPaymentMethods
    },
    async loadAccountPlannings(state, accountPlannings) {
        state.accountPlannings = accountPlannings
    },
    loadCompanyCurrentAccounts(state, companyCurrentAccounts) {
        state.companyCurrentAccounts = companyCurrentAccounts
    },
    async loadCostCenters(state, costCenters) {
        state.costCenters = costCenters
    },
    async loadLedgerAccountMovements(state, movements) {
        state.ledgerAccountMovements = movements
    },
    async loadLedgerAccountBalances(state, balances) {
        state.ledgerAccountBalances.debitTotal = balances.debitTotal
        state.ledgerAccountBalances.creditTotal = balances.creditTotal
    },
    async loadBranches(state, branches) {
        state.branches = branches
    },
    async loadCommonAccountingEntries(state, commonAccountingEntries) {
        state.commonAccountingEntries = commonAccountingEntries
    },
    async loadPaymentMethodAccountParameters(state, paymentMethodAccountParameters) {
        state.paymentMethodAccountParameters = paymentMethodAccountParameters
    },
    async loadTaxTypes(state, taxTypes) {
        state.taxTypes = taxTypes
    },
    async loadRetentionPerceptionParameters(state, retentionPerceptionParameters) {
        state.retentionPerceptionParameters = retentionPerceptionParameters
    },

    addCostCenter(state, costCenter) {
        state.costCenters.push(costCenter)
    },
    updateCostCenter(state, data) {
        Object.assign(state.costCenters[data.index], data.costCenter)
    },
    removeCostCenter(state, index) {
        state.costCenters.splice(index, 1)
    },
    addPeriodClose(state, periodClose) {
        state.periodClose.push(periodClose)
    },
    removePeriodClose(state, periodClose) {
        state.periodClose.splice(state.periodClose.indexOf(periodClose), 1)
    },
    async updateAccountingEntry(state, accountData) {
        Object.assign(state.commonAccountingEntries[accountData.index], accountData.accountEntry)
    },

    addRetentionPerceptionParameter(state, retentionPerceptionParameter) {
        state.retentionPerceptionParameters.push(retentionPerceptionParameter)
    },
    removeRetentionPerceptionParameter(state, index) {
        state.retentionPerceptionParameters.splice(index, 1)
    },
}