<template>
  <v-snackbar
    v-model="show"
    :color="color"
    class="text-lg-center"
    :top="!isError"
    :bottom="isError"
    :timeout="-1"
  >
    <v-icon class="mr-2">
      {{ getIcon }}
    </v-icon>
    {{ message }}

    <!--    Link to Route-->
    <v-btn
      v-if="toRoute"
      text class="white--text ml-3"
      @click.native="() => router().push({name: toRoute.name, params: toRoute.params})"
    >
      Ir a {{ toRoute.view }}
    </v-btn>

    <v-btn
      text class="white--text ml-3"
      @click.native="$emit('close-snack')"
    >
      Cerrar
    </v-btn>
  </v-snackbar>
</template>

<script>
import router from "@/router";

export default {
  methods: {
    router() {
      return router
    }
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    toRoute: Object,
  },
  emits: ['close-snack'],
  computed: {
    isError() {
      return this.color === 'red';
    },
    getIcon() {
      if (this.color === 'success')
        return 'mdi-check-circle'
      if (this.color === 'red')
        return 'mdi-close-circle'
      if (this.color === 'blue')
        return 'mdi-information'
      return ''
    }
  }
}
</script>