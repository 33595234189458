import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from "../store/index";

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/App/Login.vue')
    },
    {
        path: '/update_password',
        name: 'updatePassword',
        component: () => import('../views/App/PasswordUpdate.vue')
    },
    {
        path: '/',
        name: 'home',
        meta: {
            "title": "Dashboard",
        },
        component: HomeView
    },
    {
        path: '/products',
        name: 'products',
        meta: {
            "title": "Articulos",
        },
        component: () => import('../views/Products/Products.vue')
    },
    {
        path: '/families',
        name: 'families',
        meta: {
            "title": "Familias",
        },
        component: () => import('../views/Products/Families.vue')
    },
    {
        path: '/subfamilies',
        name: 'subfamilies',
        meta: {
            "title": "Subfamilias",
        },
        component: () => import('../views/Products/Subfamilies.vue')
    },
    {
        path: '/brands',
        name: 'brands',
        meta: {
            "title": "Marcas",
        },
        component: () => import('../views/Products/Brands.vue')
    },

    {
        path: '/customers/list',
        name: 'customers',
        meta: {
            "title": "Clientes",
        },
        component: () => import('../views/Customers/Customers.vue')
    },
    {
        path: '/customers/customersCA',
        name: 'customersCA',
        meta: {
            "title": "Cuenta Corriente Clientes",
        },
        component: () => import('../views/Customers/CustomersCA.vue'),
        props: true
    },
    {
        path: '/customers/customer_proofs_imputations',
        name: 'customerProofsImputations',
        meta: {
            "title": "Imputaciones",
        },
        component: () => import('../views/Customers/CustomerProofsImputations.vue'),
        props: true,
    },
    {
        path: '/customers/prices_lists/new_list',
        name: 'new_list',
        meta: {
            "title": "Lista de Precios",
        },
        component: () => import('../views/Customers/PricesLists/NewPriceList.vue')
    },
    {
        path: '/customers/prices_lists/add_products',
        name: 'add_products',
        meta: {
            "title": "Agregar Articulos a Lista",
        },
        component: () => import('../views/Customers/PricesLists/AddProductsToList.vue')
    },
    {
        path: '/customers/prices_lists/see_prices',
        name: 'see_prices',
        meta: {
            "title": "Ver Precios de Lista",
        },
        component: () => import('../views/Customers/PricesLists/SeeProductPrices.vue')
    },
    {
        path: '/customers/new_quotation',
        name: 'newQuotation',
        meta: {
            "title": "Nueva Cotización",
        },
        component: () => import('../views/Customers/NewQuotation.vue')
    },
    {
        path: '/customers/quotation_list',
        name: 'quotationsList',
        meta: {
            "title": "Cotizaciones",
        },
        component: () => import('../views/Customers/QuotationsList.vue')
    },
    {
        path: '/customers/transports',
        name: 'transports',
        meta: {
            "title": "Transportes",
        },
        component: () => import('../views/Customers/Transports.vue')
    },
    {
        path: '/customers/sellers',
        name: 'sellers',
        meta: {
            "title": "Vendedores",
        },
        component: () => import('../views/Customers/Sellers.vue')
    },

    {
        path: '/suppliers/list',
        name: 'suppliers',
        meta: {
            "title": "Proveedores",
        },
        component: () => import('../views/Suppliers/Suppliers.vue')
    },
    {
        path: '/suppliers/suppliersCA',
        name: 'suppliersCA',
        meta: {
            "title": "Cuenta Corriente Proveedores",
        },
        component: () => import('../views/Suppliers/SuppliersCA.vue'),
        props: true,
    },
    {
        path: '/suppliers/supplier_proofs_imputations',
        name: 'supplierProofsImputations',
        meta: {
            "title": "Imputaciones",
        },
        component: () => import('../views/Suppliers/SupplierProofsImputations.vue'),
        props: true,
    },
    {
        path: '/suppliers/imputations_expenses',
        name: 'imputations_expenses',
        meta: {
            "title": "Imputaciones/Gastos",
        },
        component: () => import('../views/Suppliers/ImputationsExpenses.vue')
    },
    {
        path: '/suppliers/payments_schedule',
        name: 'paymentsSchedule',
        meta: {
            "title": "Agenda de Pagos",
        },
        component: () => import('../views/Suppliers/PaymentsSchedule.vue')
    },

    {
        path: '/parameters/general',
        name: 'generalParameters',
        meta: {
            "title": "Parámetros Generales",
        },
        component: () => import('../views/App/Parameters.vue')
    },
    {
        path: '/parameters/accounting',
        name: 'accountingParameters',
        meta: {
            "title": "Parámetros Contables",
        },
        component: () => import('../views/Accounting/AccountingParameters.vue')
    },

    {
        path: '/engineering/revisions_history',
        name: 'revisionsHistory',
        meta: {
            "title": "Historial de Revisiones",
        },
        component: () => import('../views/Engineering/RevisionsHistory.vue')
    },
    {
        path: '/engineering/formulas/simple',
        name: 'formulas',
        meta: {
            "title": "Formula Simple",
        },
        component: () => import('../views/Engineering/Formulas.vue')
    },
    {
        path: '/engineering/formulas/batch',
        name: 'formulaBatch',
        meta: {
            "title": "Formula por Lote",
        },
        component: () => import('../views/Engineering/FormulaBatch.vue')
    },

    {
        path: '/quality/correlative_numbering',
        name: 'correlativeNumbering',
        meta: {
            "title": "Numeracion Correlativa",
        },
        component: () => import('../views/Quality/CorrelativeNumbering.vue')
    },
    {
        path: '/quality/tightness_control',
        name: 'tightnessControl',
        meta: {
            "title": "Control de Estanqueidad",
        },
        component: () => import('../views/Quality/TightnessControl.vue')
    },
    {
        path: '/quality/reception_control',
        name: 'receptionControl',
        meta: {
            "title": "Control de Recepcion",
        },
        component: () => import('../views/Quality/ReceptionControl.vue')
    },

    {
        path: '/production/programming_board',
        name: 'programmingBoard',
        meta: {
            "title": "Tablero de Programacion",
        },
        component: () => import('../views/Production/WorkOrders/ProgrammingBoard.vue')
    },
    {
        path: '/production/new_work_order',
        name: 'newWorkOrder',
        meta: {
            "title": "Nueva Orden de Trabajo",
        },
        component: () => import('../views/Production/WorkOrders/NewWorkOrder.vue'),
        props: true,
    },
    {
        path: '/production/new_batch_order',
        name: 'newBatchOrder',
        meta: {
            "title": "Nueva OT por Lote",
        },
        component: () => import('../views/Production/WorkOrders/NewProductionOrderBatch.vue'),
    },
    {
        path: '/production/order_tracing',
        name: 'productionOrderTracing',
        meta: {
            "title": "Seguimiento de OTs",
        },
        component: () => import('../views/Production/WorkOrders/ProductionOrderTracing.vue'),
    },
    {
        path: '/production/work_orders_list',
        name: 'workOrdersList',
        meta: {
            "title": "Ordenes de Trabajo",
        },
        component: () => import('../views/Production/WorkOrders/WorkOrdersList.vue')
    },
    {
        path: '/production/associate_wo_customer_order',
        name: 'associateWOCustomerOrder',
        meta: {
            "title": "Asociar OT a Pedido",
        },
        component: () => import('../views/Production/WorkOrders/AssociateWOCustomerOrder.vue')
    },
    {
        path: '/production/associate_wo_wo',
        name: 'associateWOWO',
        meta: {
            "title": "Asociar OTs",
        },
        component: () => import('../views/Production/WorkOrders/AssociateWOs.vue')
    },
    {
        path: '/production/priority_board',
        name: 'priorityBoard',
        meta: {
            "title": "Tablero de Prioridades",
        },
        component: () => import('../views/Production/PriorityBoard.vue')
    },
    {
        path: '/production/new_order',
        name: 'newOrder',
        meta: {
            "title": "Nuevo Pedido",
        },
        component: () => import('../views/Production/CustomerOrders/NewCustomerOrder.vue'),
        props: true
    },
    {
        path: '/production/pending_customer_orders',
        name: 'pendingCustomerOrders',
        meta: {
            "title": "Pedidos Pendientes",
        },
        component: () => import('../views/Production/CustomerOrders/CustomerOrdersList.vue'),
    },
    {
        path: '/production/delivered_customer_orders',
        name: 'deliveredCustomerOrders',
        meta: {
            "title": "Pedidos Entregados",
        },
        component: () => import('../views/Production/CustomerOrders/CustomerOrdersList.vue'),
    },
    {
        path: '/production/authorize_deliveries',
        name: 'authorizeDeliveries',
        meta: {
            "title": "Autorizar Entregas",
        },
        component: () => import('../views/Production/CustomerOrders/AuthorizeDeliveries.vue')
    },
    {
        path: '/production/authorized_deliveries',
        name: 'authorizedDeliveries',
        meta: {
            "title": "Entregas Autorizadas",
        },
        component: () => import('../views/Production/CustomerOrders/AuthorizedDeliveries.vue')
    },

    {
        path: '/production/panel_calculations/new_bar_plate',
        name: 'newBarPlateCalculation',
        meta: {
            "title": "Nuevo Calculo Placa Barra",
        },
        component: () => import('../views/Production/Calculation/NewBarPlate.vue')
    },
    {
        path: '/production/panel_calculations/print_bar_plate',
        name: 'PrintBarPlate',
        meta: {
            "title": "Imprimir Calculo Placa Barra",
        },
        component: () => import('../views/Production/Calculation/PrintBarPlate.vue')
    },
    {
        path: '/production/panel_calculations/simple_double_tube',
        name: 'SimpleDoubleTube',
        meta: {
            "title": "Nuevo Calculo Panel Simple/Doble",
        },
        component: () => import('../views/Production/Calculation/NewSimpleDoubleTube.vue')
    },
    {
        path: '/production/panel_calculations/intercooler',
        name: 'newIntercooler',
        meta: {
            "title": "Nuevo Calculo Intercooler",
        },
        component: () => import('../views/Production/Calculation/NewIntercooler.vue')
    },
    {
        path: '/production/panel_calculations/print_simple_double_tube',
        name: 'PrintSimpleDoubleTube',
        meta: {
            "title": "Imprimir Panel Simple/Doble",
        },
        component: () => import('../views/Production/Calculation/PrintSimpleDoubleTube.vue')
    },
    {
        path: '/production/panel_calculations/print_intercooler',
        name: 'PrintIntercooler',
        meta: {
            "title": "Imprimir Intercooler",
        },
        component: () => import('../views/Production/Calculation/PrintIntercooler.vue')
    },
    {
        path: '/production/control/panel_process_tracing',
        name: 'PanelProcessTracing',
        meta: {
            "title": "Control de Procesos",
        },
        component: () => import('../views/Production/PanelProcessTracing.vue')
    },
    {
        path: '/production/control/process_closure',
        name: 'processClosure',
        meta: {
            "title": "Cierre de Procesos",
        },
        component: () => import('../views/Production/ProcessClosure.vue')
    },
    {
        path: '/production/repairs/list',
        name: 'repairsList',
        meta: {
            "title": "Reparaciones",
        },
        component: () => import('../views/Production/Repair/RepairsList.vue')
    },
    {
        path: '/production/repairs/concepts',
        name: 'repairConcepts',
        meta: {
            "title": "Conceptos de Reparacion",
        },
        component: () => import('../views/Production/Repair/RepairConcepts.vue')
    },

    {
        path: '/employees/list',
        name: 'employeesList',
        meta: {
            "title": "Empleados",
        },
        component: () => import('../views/HumanResources/Employees.vue')
    },

    {
        path: '/banks/list',
        name: 'banksList',
        meta: {
            "title": "Bancos",
        },
        component: () => import('../views/Banks/BanksList.vue')
    },
    {
        path: '/banks/accounts',
        name: 'banksAccounts',
        meta: {
            "title": "Cuentas Bancarias",
        },
        component: () => import('../views/Banks/BankAccounts.vue')
    },
    {
        path: '/banks/concepts',
        name: 'bankConcepts',
        meta: {
            "title": "Conceptos Bancarios",
        },
        component: () => import('../views/Banks/BankConcepts.vue')
    },
    {
        path: '/banks/new_movement',
        name: 'newBankMovement',
        meta: {
            "title": "Nuevo Movimiento Bancario",
        },
        component: () => import('../views/Banks/NewBankMovement.vue'),
        props: true,
    },
    {
        path: '/banks/movements',
        name: 'bankMovements',
        meta: {
            "title": "Movimientos Bancarios",
        },
        component: () => import('../views/Banks/BankMovementList.vue')
    },
    {
        path: '/banks/cards',
        name: 'bankCards',
        meta: {
            "title": "Tarjetas de Credito/Debito",
        },
        component: () => import('../views/Banks/BankCards.vue')
    },
    {
        path: '/banks/wallets',
        name: 'virtualWallets',
        meta: {
            "title": "Billeteras Virtuales",
        },
        component: () => import('../views/Banks/Wallets.vue')
    },
    {
        path: '/banks/checks_list',
        name: 'checksList',
        meta: {
            "title": "Cheques",
        },
        component: () => import('../views/Banks/ChecksList.vue')
    },

    {
        path: '/accounting/account_plannings',
        name: 'accountPlannings',
        meta: {
            "title": "Cuentas Contables",
        },
        component: () => import('../views/Accounting/AccountPlannings.vue')
    },
    {
        path: '/accounting/cost_centers',
        name: 'costCenters',
        meta: {
            "title": "Centro de Costos",
        },
        component: () => import('../views/Accounting/CostCenters.vue')
    },
    {
        path: '/accounting/common_accounting_entries',
        name: 'commonAccountingEntries',
        meta: {
            "title": "Asientos Contables",
        },
        component: () => import('../views/Accounting/AccountingEntries.vue')
    },
    {
        path: '/accounting/ledger_account',
        name: 'ledgerAccount',
        meta: {
            "title": "Libro Mayor",
        },
        component: () => import('../views/Accounting/LedgerAccount.vue')
    },
    {
        path: '/accounting/customer_credit_limit',
        name: 'customerCreditLimit',
        meta: {
            "title": "Limites de Credito",
        },
        component: () => import('../views/Accounting/CustomerCreditLimit.vue')
    },
    {
        path: '/accounting/taxes/IIBB_perceptions',
        name: 'IIBBPerceptions',
        meta: {
            "title": "Percepciones IIBB",
        },
        component: () => import('../views/Accounting/Taxes/IIBBPerceptions.vue')
    },

    {
        path: '/purchase_orders/new',
        name: 'newPurchaseOrder',
        meta: {
            "title": "Nueva Orden de Compra",
        },
        component: () => import('../views/PurchaseOrders/NewPurchaseOrder.vue')
    },
    {
        path: '/purchase_orders/list',
        name: 'purchaseOrdersList',
        meta: {
            "title": "Ordenes de Compra",
        },
        component: () => import('../views/PurchaseOrders/PurchaseOrdersList.vue')
    },
    {
        path: '/purchase_orders/modify',
        name: 'modifyPurchaseOrders',
        meta: {
            "title": "Modificar Orden de Compra",
        },
        component: () => import('../views/PurchaseOrders/ModifyPurchaseOrder.vue')
    },
    {
        path: '/purchase_orders/new_internal_order',
        name: 'newInternalOrder',
        meta: {
            "title": "Nuevo Pedido Interno",
        },
        component: () => import('../views/PurchaseOrders/NewInternalPurchaseRequest.vue')
    },
    {
        path: '/purchase_orders/internal_orders_list',
        name: 'internalOrdersList',
        meta: {
            "title": "Pedidos Internos",
        },
        component: () => import('../views/PurchaseOrders/InternalPurchaseRequestsList.vue')
    },
    {
        path: '/stock/entry_departure',
        name: 'entryDeparture',
        meta: {
            "title": "Nuevo Movimiento de Stock",
        },
        component: () => import('../views/Stock/NewStockMovement.vue')
    },
    {
        path: '/stock/entries_list',
        name: 'entriesList',
        meta: {
            "title": "Movimientos de Stock",
        },
        component: () => import('../views/Stock/StockMovementsList.vue')
    },
    {
        path: '/stock/stock_movement_concepts',
        name: 'stockMovementConcepts',
        meta: {
            "title": "Conceptos de Movimientos de Stock",
        },
        component: () => import('../views/Stock/StockMovementConcepts.vue')
    },
    {
        path: '/stock/new_dispatch_note_departure',
        name: 'newDispatchNoteDeparture',
        meta: {
            "title": "Nueva Salida de Remito",
        },
        component: () => import('../views/Stock/NewDispatchNoteDeparture.vue')
    },
    {
        path: '/stock/departures_list',
        name: 'departuresList',
        meta: {
            "title": "Salidas de Remito",
        },
        component: () => import('../views/Stock/DispatchNotesDepartureList.vue')
    },

    {
        path: '/dispatch_notes/new_dispatch_note',
        name: 'newDispatchNote',
        meta: {
            "title": "Nuevo Remito",
        },
        component: () => import('../views/DispatchNotes/NewDispatchNote.vue')
    },
    {
        path: '/dispatch_notes/show_dispatch_notes',
        name: 'showDispatchNotes',
        meta: {
            "title": "Remitos",
        },
        component: () => import('../views/DispatchNotes/ShowDispatchNotes.vue')
    },
    {
        path: '/dispatch_notes/cancel_dispatch_notes',
        name: 'cancelDispatchNotes',
        meta: {
            "title": "Anular Remitos Pendientes de Facturacion",
        },
        component: () => import('../views/DispatchNotes/CancelPendingDispatchNotes.vue')
    },
    {
        path: '/dispatch_notes/associate_dispatch_note_correlative',
        name: 'associateDispatchNoteCorrelative',
        meta: {
            "title": "Asociar Remito/Correlativo",
        },
        component: () => import('../views/DispatchNotes/AssociateDispatchNoteCorrelative.vue')
    },
    {
        path: '/dispatch_notes/assoc_dispatch_invoice',
        name: 'associateDispatchInvoice',
        meta: {
            "title": "Asociar Remito/Factura",
        },
        component: () => import('../views/DispatchNotes/AssociateDispatchInvoice.vue')
    },
    {
        path: '/dispatch_notes/assoc_dispatch_customer_order',
        name: 'associateDispatchCustomerOrder',
        meta: {
            "title": "Asociar Remito/Pedido",
        },
        component: () => import('../views/DispatchNotes/AssociateDispatchCustomerOrder.vue')
    },

    {
        path: '/arca/proofs_manual_load',
        name: 'proofsManualLoad',
        meta: {
            "title": "Carga Manual de Comprobantes",
        },
        component: () => import('@/views/Arca/ProofsManualLoad.vue')
    },
    {
        path: '/arca/verify_received_proofs',
        name: 'verifyReceivedProofs',
        meta: {
            "title": "Verificar Comprobantes Recibidos",
        },
        component: () => import('@/views/Arca/VerifyAfipReceivedProofs.vue')
    },
    {
        path: '/arca/perception_txt',
        name: 'perceptionTxt',
        meta: {
            "title": "TXT Percepciones",
        },
        component: () => import('@/views/Arca/DownloadArcaTxt.vue')
    },
    {
        path: '/arca/misiones_retention_txt',
        name: 'misionesRetentionTxt',
        meta: {
            "title": "TXT Misiones",
        },
        component: () => import('@/views/Arca/DownloadArcaTxt.vue')
    },

    {
        path: '/reports/production/work_orders_by_sector',
        name: 'workOrdersBySector',
        meta: {
            "title": "OTs por Sector",
        },
        component: () => import('../views/Reports/Production/WorkOrdersBySector.vue')
    },
    {
        path: '/reports/production/work_orders_by_customer',
        name: 'workOrdersByCustomer',
        meta: {
            "title": "OTs por Cliente",
        },
        component: () => import('../views/Reports/Production/WorkOrdersByCustomer.vue')
    },
    {
        path: '/reports/production/WO_history_by_product',
        name: 'woHistoryByProduct',
        meta: {
            "title": "OTs por Articulo",
        },
        component: () => import('../views/Reports/Production/WOHistoryByProduct.vue')
    },

    {
        path: '/reports/sells/sells_iva',
        name: 'sellsIva',
        meta: {
            "title": "Libro IVA Ventas",
        },
        component: () => import('@/views/Reports/Sales/SalesIVABook.vue')
    },
    {
        path: '/reports/sells/sells_by_province',
        name: 'sellsByProvince',
        meta: {
            "title": "Ventas por Provincia",
        },
        component: () => import('@/views/Reports/Sales/SalesByProvince.vue')
    },
    {
        path: '/reports/sells/sells_ranking',
        name: 'sellsRanking',
        meta: {
            "title": "Ranking de ventas",
        },
        component: () => import('@/views/Reports/Sales/SalesRanking.vue')
    },
    {
        path: '/reports/sells/sells_by_customer_and_period',
        name: 'sellsByCustomerAndPeriod',
        meta: {
            "title": "Art. vendidos por cliente y período",
        },
        component: () => import('@/views/Reports/Sales/SalesByCustomerAndPeriod.vue')
    },
    {
        path: '/reports/sells/sells_by_family_and_subfamily',
        name: 'sellsByFamilyAndSubfamily',
        meta: {
            "title": "Ventas por Familia y Sub Familia",
        },
        component: () => import('@/views/Reports/Sales/SalesByFamilyAndSubfamily.vue')
    },
    {
        path: '/reports/sells/interests_for_out_term_payments',
        name: 'interestsForOutTermPayments',
        meta: {
            "title": "Intereses por pago fuera de término",
        },
        component: () => import('@/views/Reports/Sales/OutOfTermInterests.vue')
    },
    {
        path: '/reports/sells/debtor_balance_summary',
        name: 'debtorBalanceSummary',
        meta: {
            "title": "Resumen Saldo Deudor",
        },
        component: () => import('@/views/Reports/Sales/ExpiredDebtorBalances.vue'),
        props: {summaryType: 'expiredBalances'}
    },
    {
        path: '/reports/sells/customer_ca_summary',
        name: 'customerCaSummary',
        meta: {
            "title": "Resumen Cuenta Corriente Cliente",
        },
        component: () => import('@/views/Reports/Sales/CustomerCASummary.vue'),
    },
    {
        path: '/reports/sells/customers_by_seller',
        name: 'customersBySeller',
        meta: {
            "title": "Clientes por Vendedor",
        },
        component: () => import('@/views/Reports/Sales/CustomersBySeller.vue'),
    },
    {
        path: '/reports/sells/sells_commissions',
        name: 'sellsCommissions',
        meta: {
            "title": "Comisiones a Vendedores",
        },
        component: () => import('@/views/Reports/Sales/SellersCommissions.vue'),
    },
    {
        path: '/reports/sells/collections_in_a_period',
        name: 'collectionsInAPeriod',
        meta: {
            "title": "Cobranzas Entre Fechas",
        },
        component: () => import('@/views/Reports/Sales/CollectionsInPeriod.vue'),
    },
    {
        path: '/reports/sells/prices_list',
        name: 'pricesList',
        meta: {
            "title": "Listas de Precios",
        },
        component: () => import('@/views/Reports/Sales/PricesListReport.vue'),
    },
    {
        path: '/reports/sells/prices_lists_by_customer',
        name: 'pricesListByCustomer',
        meta: {
            "title": "Listas de Precios por Cliente",
        },
        component: () => import('@/views/Reports/Sales/PricesListsByCustomer.vue'),
    },

    {
        path: '/reports/suppliers/supplier_ca_summary',
        name: 'supplierCASummary',
        meta: {
            "title": "Resumen Cuenta Corriente",
        },
        component: () => import('../views/Reports/Suppliers/SupplierCASummary.vue'),
    },
    {
        path: '/reports/suppliers/purchases_book',
        name: 'purchasesBook',
        meta: {
            "title": "Libro IVA Compras",
        },
        component: () => import('../views/Reports/Suppliers/PurchasesIVABook.vue'),
    },
    {
        path: '/reports/suppliers/creditor_balance',
        name: 'creditorBalance',
        meta: {
            "title": "Resumen Saldo Acreedor",
        },
        component: () => import('../views/Reports/Suppliers/CreditorBalance.vue'),
    },
    {
        path: '/reports/suppliers/payment_orders',
        name: 'paymentOrders',
        meta: {
            "title": "Pagos entre Fechas",
        },
        component: () => import('../views/Reports/Suppliers/PaymentOrdersReport.vue'),
    },
    {
        path: '/reports/suppliers/accumulated_by_imputations',
        name: 'accumulatedByImputations',
        meta: {
            "title": "Acumulado por Imputaciones",
        },
        component: () => import('../views/Reports/Suppliers/AccumulatedByImputations.vue'),
    },
    {
        path: '/reports/suppliers/imputation_details',
        name: 'imputationDetails',
        meta: {
            "title": "",
        },
        component: () => import('../views/Reports/Suppliers/ImputationDetailsReport.vue'),
    },

    {
        path: '/reports/customer_orders/orders_by_customer_period',
        name: 'ordersByCustomerPeriod',
        meta: {
            "title": "Art. Pedidos por Cliente y Período",
        },
        component: () => import('../views/Reports/CustomerOrders/OrdersByCustomerAndPeriod.vue'),
    },

    {
        path: '/reports/stock/stock_movement_history',
        name: 'stockMovementHistory',
        meta: {
            "title": "Histórico de Movimientos de Artículos",
        },
        component: () => import('../views/Reports/Stock/StockMovementHistory.vue'),
    },

    {
        path: '/reports/banks/bank_movement_history',
        name: 'bankMovementHistory',
        meta: {
            "title": "Histórico de Movimientos Bancarios",
        },
        component: () => import('../views/Reports/Banks/BankMovementHistory.vue'),
    },
    {
        path: '/reports/banks/third_party_checks_inventory',
        name: 'thirdPartyChecksInventory',
        meta: {
            "title": "Inventario Cheques de Terceros",
        },
        component: () => import('../views/Reports/Banks/ThirdPartyChecksInventory.vue'),
    },
    {
        path: '/reports/banks/third_party_checks_delivered',
        name: 'thirdPartyChecksDelivered',
        meta: {
            "title": "Cheques de Terceros Ingresados y Entregados",
        },
        component: () => import('../views/Reports/Banks/ThirdPartyChecksDelivered.vue'),
    },
    {
        path: '/reports/banks/own_checks_delivered',
        name: 'ownChecksDelivered',
        meta: {
            "title": "Cheques Propios Emitidos y Entregados",
        },
        component: () => import('../views/Reports/Banks/OwnChecksDelivered.vue'),
    },

    {
        path: '/reports/quality/rg_010_form',
        name: 'rg010Form',
        meta: {
            "title": "RG-010 Form. Estanqueidad",
        },
        component: () => import('../views/Reports/Quality/RG010TightnessForm.vue'),
    },
    {
        path: '/reports/quality/rg_010_report',
        name: 'rg010Report',
        meta: {
            "title": "RG-010 Reporte Estanqueidad",
        },
        component: () => import('../views/Reports/Quality/RG010TightnessReport.vue'),
    },
    {
        path: '/reports/quality/rg_014_form',
        name: 'rg014Form',
        meta: {
            "title": "RG-014 Form. Num. Correlativa de Radiadores",
        },
        component: () => import('../views/Reports/Quality/RG014CorrelativeForm.vue'),
    },
    {
        path: '/reports/quality/tracing',
        name: 'productTracing',
        meta: {
            "title": "Trazabilidad",
        },
        component: () => import('../views/Reports/Quality/ProductTracing.vue'),
    },

    {
        path: '/reports/purchases/purchased_products',
        name: 'purchasedProducts',
        meta: {
            "title": "Artículos Comprados",
        },
        component: () => import('../views/Reports/Purchases/PurchasesBySupplierAndPeriod.vue'),
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (to.name !== 'login') {
        localStorage.setItem('LS_ROUTE_KEY', to.name)
    }

    const isAuthenticated = store.getters["app/getIfAuthenticated"]
    const shouldLogin = Boolean((to.name !== 'login') && !isAuthenticated)
    const shouldNotLogin = Boolean((to.name === 'login') && isAuthenticated)

    if (shouldLogin) {
        next({name: 'login'})
        return
    }
    if (shouldNotLogin) {
        next({name: 'home'})
        return
    }
    next()
})

router.afterEach((to) => {
    store.commit('app/setViewTitle', to.meta.title)
})

export default router
